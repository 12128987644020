import Actions from '@/components/actions';
import FormDatePicker from '@/components/form/fields/datePicker';
import FormSelect from '@/components/form/fields/select';
import DateRangePickerFields from '@/components/muiDatePickerFields/dateRangePickerSlot';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import {
	CancelRounded as CancelRoundedIcon,
	FileDownload as FileDownloadIcon,
	PictureAsPdf as PictureAsPdfIcon,
	QueryStats as QueryStatsIcon,
} from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { Grid, Link, MenuItem, Paper, Stack, useMediaQuery } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { addMonths, endOfDay, startOfMonth, subMonths } from 'date-fns';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import React, { Fragment, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import safeDateFns from '../../../../helpers/safeFormat';
import ClientSelect from '../../../formSelects/clientSelect';
import StaffSelect from '../../../formSelects/staffSelect';
import VendorSelect from '../../../formSelects/vendorSelect';
import useExportCSV from './useExportCSV';

export const areDatesValid = ( givenDates ) => !isEmpty( givenDates ) && ( givenDates.filter( Boolean )?.length === 1 || Boolean( givenDates.find( ( date ) => !safeDateFns.isValid( date ) ) ) );

export default function ItemReportFilters( { type, isGrouped }: { type: string, isGrouped?: boolean } ) {
	const { t } = useTranslation();
	const formik = useFormikContext<any>();
	const linkRef = useRef( null );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const handleExportCSV = useExportCSV( type, linkRef, isGrouped );
	const viewAllCommerces = checkViewAllPermission( 'commerces' );
	const viewAllPayments = checkViewAllPermission( 'payments' );
	const viewAllPurchases = checkViewAllPermission( 'purchases' );
	
	const dates = formik.values.newDates;
	const startDate = formik.values?.startDate;
	const endDate = formik.values?.endDate;
	
	const invalidDates = useMemo( () => {
		
		if ( type === 'payment' ) {
			const paymentDates = [ startDate, endDate ];
			return areDatesValid( paymentDates );
		} else {
			return areDatesValid( dates );
		}
		
	}, [ startDate, endDate, dates ] );
	
	return (
		<Fragment>
			<Link ref={linkRef} sx={{ display: 'none' }}>{t( 'common:csv-link' )}</Link>
			<Paper variant='borderless' sx={{ p: 2, mb: 2 }}>
				<Grid container spacing={1} mt={.5}>
					<Grid item md xs={12} lg={3}>
						{type === 'payment' || type === 'houseAccount' ? (
							<Stack direction='row' spacing={1}>
								<FormDatePicker
									ampm
									ampmInClock
									name='startDate'
									label={t( 'common:start-date' )}
									maxDate={formik.values.endDate || addMonths( formik.values.startDate || new Date(), 12 )}
									type='datetime-local'
									textFieldProps={{ fullWidth: isMobile && true }}
								/>
								<FormDatePicker
									ampm
									ampmInClock
									name='endDate'
									label={t( 'common:end-date' )}
									minDate={formik.values.startDate || subMonths( formik.values.startDate || new Date(), 12 )}
									type='datetime-local'
									textFieldProps={{ fullWidth: isMobile && true }}
								/>
							</Stack>
						) : (
							<DateRangePicker
								value={formik.values.newDates}
								minDate={!dates?.[ 0 ] ? subMonths( new Date(), 1 ) : undefined}
								maxDate={addMonths( dates?.[ 0 ] || new Date(), 12 )}
								slots={{ field: DateRangePickerFields }}
								onChange={( newValue ) => {
									if ( newValue[ 0 ] && newValue[ 1 ] ) {
										formik.setFieldValue( 'newDates', [ newValue[ 0 ], endOfDay( newValue[ 1 ] ) ] );
									} else {
										formik.setFieldValue( 'newDates', [ null, null ] );
									}
								}}
							/>
						)}
					</Grid>
					{( type === 'purchase' || type === 'item' ) && (
						<Grid item md xs={12} lg={type === 'purchase' ? 3 : 1.5}>
							<VendorSelect
								variables={{ options: { filter: { active: true } } }}
								name='menu'
							/>
						</Grid>
					)}
					{![ 'houseAccount', 'purchase' ].includes( type ) && (
						<Grid item md xs={12} lg={type === 'payment' ? 3 : 1.5}>
							<ClientSelect
								fullWidth
								name='client'
								onAdd={undefined}
							/>
						</Grid>
					)}
					{type !== 'purchase' && type !== 'houseAccount' && (
						<Grid item md xs={12} lg={1.5}>
							<FormSelect
								fullWidth
								name='type'
								placeholder='Type'
								label={t( 'common:commerce' )}
								onChange={( event ) => {
									formik.setFieldValue( 'type', event.target.value === 'clear' ? null : event.target.value );
								}}>
								<MenuItem value='clear'>Both (Clear selection)</MenuItem>
								<MenuItem selected value='ORDER'>{t( 'common:orders' )}</MenuItem>
								<MenuItem value='INVOICE'>{t( 'common:invoices' )}</MenuItem>
							</FormSelect>
						</Grid>
					)}
					{Boolean( type === 'item' ? viewAllCommerces : type === 'purchase'
						? viewAllPurchases
						: type === 'payment' ? viewAllPayments : undefined ) && (
						<Grid item md xs={12} lg={1.5} pr={2}>
							<StaffSelect
								name='placedBy'
								variables={{ options: { limit: 40, filter: { user: { $ne: null } } } }}
							/>
						</Grid>
					)}
					<Grid
						item
						md
						xs={12}
						lg={1.5}
						mt={3}
						sx={{ display: 'flex', justifyContent: 'end' }}>
						<Actions
							separated
							max={2}
							menuListProps={{ sx: { width: 'max-content' } }}
							items={[ {
								name       : t( 'management:search' ),
								icon       : <QueryStatsIcon/>,
								buttonProps: {
									disabled: invalidDates,
									variant : 'contained',
									color   : 'primary',
									sx      : { maxWidth: 150 },
								},
								onClick: formik.submitForm,
							}, {
								name       : t( 'management:clear' ),
								icon       : <CancelRoundedIcon/>,
								buttonProps: { color: 'warning', sx: { maxWidth: 150 } },
								onClick    : async () => {
									await formik.setFieldValue( 'newDates', [ startOfMonth( new Date() ),
										endOfDay( new Date() ) ] );
									await formik.setFieldValue( 'type', null );
									await formik.setFieldValue( 'placedBy', null );
									await formik.setFieldValue( 'startDate', null );
									await formik.setFieldValue( 'endDate', null );
									await formik.setFieldValue( 'client', null );
									await formik.submitForm();
								},
							}, !isMobile && type !== 'stock' && {
								name   : t( 'common:export' ),
								icon   : <FileDownloadIcon/>,
								onClick: handleExportCSV,
							}, !isMobile && type === 'payment' && {
								name   : t( 'common:pdf' ),
								icon   : <PictureAsPdfIcon/>,
								onClick: () => handleExportCSV( 'pdf' ),
							} ]}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Fragment>
	);
}
